
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router } from 'react-router-dom';

//========  layoutPostLoginis HOC  =========== 

export const routes  = (
        <div className="col-lg-10 col-lg-offset-2">
            <Router>
                  
            </Router>
        </div>
);

